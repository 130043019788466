<template>
  <b-card-actions
    :title="group.title"
    style="border-style: solid;"
    action-close
    action-collapse
  >
    <b-form-group
      label="Tiêu đề"
      label-for="question-group-title"
    >
      <b-form-input
        id="question-group-title"
        v-model="group.title"
        trim
      />
    </b-form-group>
    <b-form-group
      label="Mô tả"
      label-for="question-group-description"
    >
      <b-form-textarea
        id="question-group-description"
        v-model="group.description"
        trim
      />
    </b-form-group>
    <b-card-actions
      v-for="(question, qidx) in group.reading_single_questions"
      :key="qidx"
      action-close
      action-collapse
      style="border-style: solid;"
      :title="'Question ' + question.question_index"
      @close="onCloseCard()"
    >
      <b-form-group
        label="Câu hỏi số"
        label-for="single-question-question-index"
      >
        <b-form-input
          id="single-question-question-index"
          v-model="question.question_index"
          trim
          type="number"
          min="1"
          max="40"
        />
      </b-form-group>
      <b-form-group
        label="Nội dung"
        label-for="single-question-content"
      >
        <b-form-input
          id="single-question-content"
          v-model="question.content"
          placeholder="You are allowed to stop off at an intermediate station when making a journey using a standard season ticket."
          trim
        />
      </b-form-group>
      <b-form-group
        label="Đáp án đúng"
        label-for="correct-ans"
        class="mt-2"
      >
        <v-select
          v-model="question.correct_answer"
          :options="yesNoNotGivenReading.answerOptions"
          :clearable="false"
          input-id="correct-ans"
        />
      </b-form-group>
    </b-card-actions>
    <!--Action-->
    <div class="d-flex mt-2">
      <b-button
        variant="primary"
        class="mr-2"
        @click="addSingleDefaultQuestion()"
      >
        Thêm câu hỏi
      </b-button>
    </div>
  </b-card-actions>
</template>

<script>
import { yesNoNotGivenReading, yesNoNotGivenReadingSingleQuestion } from '@core/utils/tests/test_constants'
import {
  BButton, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'

export default {
  name: 'YesNoNotGivenReading',
  components: {
    BFormGroup, BFormInput, vSelect, BFormTextarea, BCardActions, BButton,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      yesNoNotGivenReading,
    }
  },
  methods: {
    onCloseCard() {
      console.log('Close and remove')
    },
    addSingleDefaultQuestion() {
      const question = JSON.parse(JSON.stringify(yesNoNotGivenReadingSingleQuestion))
      const nextIndex = Number(sessionStorage.getItem('next_question_index') || 1)
      question.question_index = nextIndex === 1 ? 1 : nextIndex
      sessionStorage.setItem('next_question_index', nextIndex === 1 ? 2 : question.question_index + 1)
      this.group.reading_single_questions.push(question)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
