<template>
  <b-card-actions
    :title="group.title"
    style="border-style: solid;"
    action-close
    action-collapse
  >
    <b-form-group
      label="Tiêu đề"
      label-for="question-group-title"
    >
      <b-form-input
        id="question-group-title"
        v-model="group.title"
        trim
      />
    </b-form-group>
    <b-form-group
      label="Mô tả"
      label-for="question-group-description"
    >
      <b-form-textarea
        id="question-group-description"
        v-model="group.description"
        trim
      />
    </b-form-group>
    <b-form-group
      v-if="group.reading_single_questions"
      label="Nội dung"
      label-for="single-question-content"
    >
      <quill-editor
        id="single-question-content"
        v-model="group.reading_single_questions[0].content"
        :options="snowOption"
        placeholder="<div>The insurance programme excludes ###INPUT_13### employees.\n An employee’s partner is not automatically ###INPUT_14### for the insurance.\n The insurance coverage for part-timers is related to their usual number of ###INPUT_15###.\nEmployees can send in a ###INPUT_16### for extra insurance.</div>."
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      />
    </b-form-group>
    <b-form-group
      v-if="group.reading_single_questions"
      label="Đáp án đúng"
      label-for="correct-ans"
      class="mt-2"
    >
      <b-form-textarea
        id="single-question-correct-ans"
        v-model="group.reading_single_questions[0].correct_answer"
        trim
      />
    </b-form-group>
  </b-card-actions>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import ImageResize from 'quill-image-resize-module'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'TextCompletionReading',
  components: {
    BFormGroup, BFormInput, vSelect, BFormTextarea, BCardActions, BButton, quillEditor,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
    }
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onEditorBlur(quill) {
    },
    // eslint-disable-next-line no-unused-vars
    onEditorFocus(quill) {
    },
    // eslint-disable-next-line no-unused-vars
    onEditorReady(quill) {
      window.Quill.register('modules/imageResize', ImageResize)
    },
    onCloseCard() {
      console.log('Close and remove')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
