<template>
  <div class="d-flex mt-2">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mr-2"
      type="submit"
      @click="$emit('add-yes-no-notgiven')"
    >
      Yes/No/NotGiven
    </b-button>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      type="button"
      variant="primary"
      class="mr-2"
      @click="$emit('add-text-completion')"
    >
      Text Completion
    </b-button>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      type="button"
      variant="primary"
      class="mr-2"
      @click="$emit('add-multiple-choice-one-answer')"
    >
      Multiple Choice One Answer
    </b-button>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      type="button"
      variant="primary"
      class="mr-2"
      @click="$emit('add-multiple-choice-multiple-answer')"
    >
      Multiple Choice Multiple Answer
    </b-button>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      type="button"
      variant="primary"
      class="mr-2"
      @click="$emit('add-matching-heading')"
    >
      Matching Heading
    </b-button>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      type="button"
      variant="primary"
      class="mr-2"
      @click="$emit('add-summary-completion-multiple-choice')"
    >
      Summary Completion (selecting from a list of words or phrases)
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ReadingQuestionTypeBar',
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
}
</script>

<style scoped>

</style>
