<template>
  <b-card-actions
    :title="group.title"
    style="border-style: solid;"
    action-close
    action-collapse
  >
    <b-form-group
      label="Tiêu đề"
      label-for="question-group-title"
    >
      <b-form-input
        id="question-group-title"
        v-model="group.title"
        trim
      />
    </b-form-group>
    <b-form-group
      label="Mô tả"
      label-for="question-group-description"
    >
      <b-form-textarea
        id="question-group-description"
        v-model="group.description"
        trim
      />
    </b-form-group>
    <b-card-actions
      v-for="(question, qidx) in group.reading_single_questions"
      :key="qidx"
      action-close
      action-collapse
      style="border-style: solid;"
      :title="'Question ' + question.question_index"
      @close="onCloseCard()"
    >
      <b-form-group
        label="Câu hỏi số"
        label-for="single-question-question-index"
      >
        <b-form-input
          id="single-question-question-index"
          v-model="question.question_index"
          trim
          type="number"
          min="1"
          max="40"
        />
      </b-form-group>
      <b-form-group
        label="Nội dung"
        label-for="single-question-content"
      >
        <b-form-textarea
          id="single-question-content"
          v-model="question.content"
          trim
        />
      </b-form-group>
      <b-form-group
        label="Các lựa chọn"
        label-for="single-question-choices"
      >
        <b-row
          v-for="(choice, choiceIdx) in question.choices"
          :key="choiceIdx"
        >
          <b-col
            cols="11"
          >
            <b-form-group
              :label="`Đáp án ${choiceIdx + 1}`"
              :label-for="'single-question-choices'"
              label-cols-md="2"
            >
              <b-form-input
                id="single-question-choices"
                v-model="question.choices[choiceIdx]"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-button
              variant="danger"
              class="px-1"
              @click="question.choices.splice(choiceIdx, 1)"
            >
              Xóa
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
      <!--Action-->
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          class="mr-2"
          @click="addSingleDefaultQuestion(question)"
        >
          Thêm lựa chọn
        </b-button>
      </div>
      <b-form-group
        label="Đáp án đúng"
        label-for="correct-ans"
        class="mt-2"
      >
        <v-select
          v-model="question.correct_answer"
          :options="question.choices"
          :clearable="false"
          input-id="correct-ans"
        />
      </b-form-group>
    </b-card-actions>
  </b-card-actions>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BFormTextarea, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  name: 'MultipleChoiceOneAnswerReading',
  components: {
    BFormGroup, BFormInput, vSelect, BFormTextarea, BCardActions, BButton, BRow, BCol,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
  },
  methods: {
    onCloseCard() {
      console.log('Close and remove')
    },
    addSingleDefaultQuestion(question) {
      question.choices.push('')
    },
  },
}
</script>

<style scoped>

</style>
