<template>
  <b-card
    id="reading-add"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h2 class="mb-0">
          Thêm mới bài test Reading
        </h2>

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Tiêu đề"
            rules="required"
          >
            <b-form-group
              label="Tiêu đề"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="testData.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Reading Test tháng 01/2022"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Plan -->
          <validation-provider
            #default="validationContext"
            name="Loại"
            rules="required"
          >
            <b-form-group
              label="Loại"
              label-for="plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="testData.plan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="plan"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Status -->
          <validation-provider
            #default="validationContext"
            name="Trạng thái"
            rules="required"
          >
            <b-form-group
              label="Trạng thái"
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="testData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div
            v-for="(section, id) in testData.reading_sections"
            :key="id"
          >
            <b-card
              :title="section.title"
              style="border-style: solid;"
            >
              <b-form-group
                label="Bài đọc"
                label-for="section-content"
              >
                <quill-editor
                  id="section-content"
                  v-model="section.reading_content"
                  :options="snowOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
                />
              </b-form-group>
              <br>
              <p>Câu hỏi</p>
              <b-form-group
                v-for="(group, idx) in section.reading_question_groups"
                :key="idx"
              >
                <component
                  :is="group.component_name"
                  v-bind="{ group, section }"
                />
              </b-form-group>
              <reading-question-type-bar
                @add-yes-no-notgiven="addYesNoNotGivenReading(section)"
                @add-text-completion="addTextCompletion(section)"
                @add-multiple-choice-one-answer="addMultipleChoiceOneAnswer(section)"
                @add-multiple-choice-multiple-answer="addMultipleChoiceMultipleAnswer(section)"
                @add-matching-heading="addMatchingHeading(section)"
                @add-summary-completion-multiple-choice="addSummaryCompletionMultipleChoice(section)"
              />
            </b-card>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Lưu thông tin
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="resetForm"
            >
              Xóa dữ liệu
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-card>
</template>

<script>
import { required, alphaNum, email } from '@validations'
import {
  ref, watch, computed, onUnmounted, watchEffect,
} from '@vue/composition-api'
import {
  arrayIntersection, extractInputMatchPattern, intersection, useRouter,
} from '@core/utils/utils'
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard,
} from 'bootstrap-vue'
import { PLAN_PREE, PLAN_PREMIUM, planOptions } from '@core/utils/plan'
import {
  TEST_STATUS_DRAFT,
  statusOptions,
  blankReadingSection1, yesNoNotGivenReading, textCompletionReading,
  multipleChoiceOneAnswerReading, multipleChoiceMultipleAnswerReading,
  matchingHeadingQuestionReading, summaryCompletionMultipleChoiceReading,
} from '@core/utils/tests/test_constants'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-module'
import ReadingQuestionTypeBar from '@/views/tests/components/ReadingQuestionTypeBar'
import YesNoNotGivenReading from '@/views/tests/components/reading/YesNoNotGivenReading'
import TextCompletionReading from '@/views/tests/components/reading/TextCompletionReading'
import MultipleChoiceOneAnswerReading from '@/views/tests/components/reading/MultipleChoiceOneAnswerReading'
import MultipleChoiceMultipleAnswerReading from '@/views/tests/components/reading/MultipleChoiceMultipleAnswerReading'
import MatchingHeadingReading from '@/views/tests/components/reading/MatchingHeadingReading'
import SummaryCompletionMultipleChoice from "@/views/tests/components/reading/SummaryCompletionMultipleChoice";

export default {
  name: 'ReadingAdd',
  directives: {
    Ripple,
  },
  components: {
    ReadingQuestionTypeBar,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,

    // question type components
    YesNoNotGivenReading,
    TextCompletionReading,
    MultipleChoiceOneAnswerReading,
    MultipleChoiceMultipleAnswerReading,
    MatchingHeadingReading,
    SummaryCompletionMultipleChoice,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onEditorBlur(quill) {
      this.testData.reading_sections.forEach(section => {
        section.reading_question_groups.forEach(ele => {
          if (ele.question_type === 'MATCHING_HEADING') {
            const inputOptions = extractInputMatchPattern(section.reading_content, /###INPUT_\d{1,}###/g) || []
            ele.reading_single_questions.forEach(question => {
              const newAnswerMaps = {}
              for (const inputOptionKey of inputOptions) {
                if (question.correct_answer.hasOwnProperty(inputOptionKey)) {
                  if (question.correct_answer[inputOptionKey] !== undefined) {
                    newAnswerMaps[inputOptionKey] = question.correct_answer[inputOptionKey]
                  } else {
                    newAnswerMaps[inputOptionKey] = ''
                  }
                } else {
                  newAnswerMaps[inputOptionKey] = ''
                }
              }
              question.correct_answer = newAnswerMaps
            })
          }
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    onEditorFocus(quill) {
    },
    // eslint-disable-next-line no-unused-vars
    onEditorReady(quill) {
      window.Quill.register('modules/imageResize', ImageResize)
    },
    addYesNoNotGivenReading(section) {
      section.reading_question_groups.push(JSON.parse(JSON.stringify(yesNoNotGivenReading.defaultValue)))
    },
    addTextCompletion(section) {
      section.reading_question_groups.push(JSON.parse(JSON.stringify(textCompletionReading.defaultValue)))
    },
    addMultipleChoiceOneAnswer(section) {
      section.reading_question_groups.push(JSON.parse(JSON.stringify(multipleChoiceOneAnswerReading.defaultValue)))
    },
    addMultipleChoiceMultipleAnswer(section) {
      section.reading_question_groups.push(JSON.parse(JSON.stringify(multipleChoiceMultipleAnswerReading.defaultValue)))
    },
    addMatchingHeading(section) {
      section.reading_question_groups.push(JSON.parse(JSON.stringify(matchingHeadingQuestionReading.defaultValue)))
    },
    addSummaryCompletionMultipleChoice(section) {
      section.reading_question_groups.push(JSON.parse(JSON.stringify(summaryCompletionMultipleChoiceReading.defaultValue)))
    },
  },
  setup(props, { emit }) {
    const { route } = useRouter()

    const blankReadingTestData = {
      title: '',
      plan: route.value.query.plan === PLAN_PREMIUM ? PLAN_PREMIUM : PLAN_PREE,
      status: TEST_STATUS_DRAFT,
      reading_sections: [
        blankReadingSection1,
      ],
    }

    const testData = ref(JSON.parse(JSON.stringify(blankReadingTestData)))
    const resetTestData = () => {
      testData.value = JSON.parse(JSON.stringify(blankReadingTestData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetTestData)

    const onSubmit = () => {
      store.dispatch('test-reading/createTest', testData.value)
        .then(() => {
          emit('refetch-data')
        })
    }

    return {
      testData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      planOptions,
      statusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
