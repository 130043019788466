<template>
  <b-card-actions
    :title="group.title"
    style="border-style: solid;"
    action-close
    action-collapse
  >
    <b-form-group
      label="Tiêu đề"
      label-for="question-group-title"
    >
      <b-form-input
        id="question-group-title"
        v-model="group.title"
        trim
      />
    </b-form-group>
    <b-form-group
      label="Mô tả"
      label-for="question-group-description"
    >
      <b-form-textarea
        id="question-group-description"
        v-model="group.description"
        trim
      />
    </b-form-group>

    <b-form-group
      label="Câu hỏi bắt đầu"
      label-for="single-question-from-index"
    >
      <b-form-input
        id="single-question-from-index"
        v-model="question.from_index"
        trim
        type="number"
        min="1"
        max="40"
      />
    </b-form-group>
    <b-form-group
      label="Câu hỏi kết thúc"
      label-for="single-question-to-index"
    >
      <b-form-input
        id="single-question-to-index"
        v-model="question.to_index"
        trim
        type="number"
        min="1"
        max="40"
      />
    </b-form-group>
    <b-form-group
      label="Nội dung"
      label-for="single-question-content"
    >
      <quill-editor
        id="single-question-content"
        v-model="question.content"
        :options="snowOption"
        placeholder="<div>The insurance programme excludes ###INPUT_13### employees.\n An employee’s partner is not automatically ###INPUT_14### for the insurance.\n The insurance coverage for part-timers is related to their usual number of ###INPUT_15###.\nEmployees can send in a ###INPUT_16### for extra insurance.</div>."
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      />
    </b-form-group>
    <b-form-group
      label="Các lựa chọn"
      label-for="single-question-choices"
    >
      <b-row
        v-for="(choice, choiceIdx) in question.choices"
        :key="choiceIdx"
      >
        <b-col
          cols="11"
        >
          <b-form-group
            :label="`Đáp án ${choiceIdx + 1}`"
            :label-for="'single-question-choices'"
            label-cols-md="2"
          >
            <b-form-input
              id="single-question-choices"
              v-model="question.choices[choiceIdx]"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-button
            variant="danger"
            class="px-1"
            @click="question.choices.splice(choiceIdx, 1)"
          >
            Xóa
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
    <!--Action-->
    <div class="d-flex mt-2">
      <b-button
        variant="primary"
        class="mr-2"
        @click="addSingleDefaultQuestion(question)"
      >
        Thêm lựa chọn
      </b-button>
    </div>
    <b-form-group
      label="Đáp án đúng"
      label-for="correct-ans"
      class="mt-2"
    >
      <b-row
        v-for="(value, key) of question.correct_answer"
        :key="key"
      >
        <b-col cols="12">
          <b-form-group
            :label="key"
            :label-for="'single-question-choices'"
            label-cols-md="3"
          >
            <v-select
              v-model="question.correct_answer[key]"
              :options="question.choices"
              :clearable="false"
              input-id="single-question-choices"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
  </b-card-actions>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import ImageResize from 'quill-image-resize-module'
import { quillEditor } from 'vue-quill-editor'
import { extractInputMatchPattern } from '@core/utils/utils'

export default {
  name: 'SummaryCompletionMultipleChoice',
  components: {
    BFormGroup, BFormInput, vSelect, BFormTextarea, BCardActions, BButton, BRow, BCol, quillEditor,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
    }
  },
  computed: {
    question() {
      return this.group.reading_single_questions[0]
    },
  },
  methods: {
    onCloseCard() {
      console.log('Close and remove')
    },
    addSingleDefaultQuestion(question) {
      question.choices.push('')
    },
    // eslint-disable-next-line no-unused-vars
    onEditorBlur(quill) {
      const inputOptions = extractInputMatchPattern(this.question.content, /###INPUT_\d{1,}###/g) || []
      const newAnswerMaps = {}
      for (const inputOptionKey of inputOptions) {
        if (this.question.correct_answer.hasOwnProperty(inputOptionKey)) {
          if (this.question.correct_answer[inputOptionKey] !== undefined) {
            newAnswerMaps[inputOptionKey] = this.question.correct_answer[inputOptionKey]
          } else {
            newAnswerMaps[inputOptionKey] = ''
          }
        } else {
          newAnswerMaps[inputOptionKey] = ''
        }
      }
      this.question.correct_answer = newAnswerMaps
    },
    // eslint-disable-next-line no-unused-vars
    onEditorFocus(quill) {
    },
    // eslint-disable-next-line no-unused-vars
    onEditorReady(quill) {
      window.Quill.register('modules/imageResize', ImageResize)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
